export const gender = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'intersex', label: 'Intersex' },
];
export const treatmentStablised = [
  { value: 'Sublingual buprenorphine', label: 'Sublingual buprenorphine' },
  { value: 'Buprenorphine/ Naloxone', label: 'Buprenorphine/ Naloxone' },
];
export const drugAddiction = [
  { value: 'heroin', label: 'Heroin' },
  { value: 'fentanyl', label: 'Fentanyl' },
  { value: 'oxycodone', label: 'Oxycodone' },
  { value: 'hydrocodone', label: 'Hydrocodone' },
  { value: 'codeine', label: 'Codeine' },
  { value: 'morphine', label: 'Morphine' },
  { value: 'other', label: 'Other' },
];
export const boolean = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

export const weeklyDrugs = [
  { value: '8', label: '8 mg' },
  { value: '16', label: '16 mg' },
  { value: '24', label: '24 mg' },
  { value: '32', label: '32 mg' },
];

export const monthlyDrugs = [
  { value: '64', label: '64 mg' },
  { value: '96', label: '96 mg' },
  { value: '128', label: '128 mg' },
];

export const additionalDrugs = [
  { value: '1', label: '1 x 8 mg' },
  { value: '2', label: '2 x 8 mg' },
  { value: '3', label: '3 x 8 mg' },
  { value: '4', label: '4 x 8 mg' },
  { value: '5', label: '5 x 8 mg' },
  { value: '6', label: '6 x 8 mg' },
  { value: '7', label: '7 x 8 mg' },
  { value: '8', label: '8 x 8 mg' },
  { value: '9', label: '9 x 8 mg' },
];

export const title = [
  { value: 'Mr', label: 'Mr' },
  { value: 'Ms', label: 'Ms' },
  { value: 'Miss', label: 'Miss' },
  { value: 'Mrs', label: 'Mrs' },
  { value: 'Mx', label: 'Mx' },
  { value: 'Master', label: 'Master' },
  { value: 'Sir', label: 'Sir' },
  { value: 'Madam', label: 'Madam' },
  { value: 'Dame', label: 'Dame' },
  { value: 'Lord', label: 'Lord' },
  { value: 'Dr', label: 'Dr' },
  { value: 'Prof', label: 'Prof' },
  { value: 'Br', label: 'Br' },
  { value: 'Sr', label: 'Sr' },
  { value: 'Fr', label: 'Fr' },
  { value: 'Rev', label: 'Rev' },
  { value: 'Pr', label: 'Pr' },
  { value: 'Elder', label: 'Elder' },
  { value: 'Captain', label: 'Captain' },
];

export const state = [
  { value: 'NSW', label: 'New South Wales' },
  { value: 'QLD', label: 'Queensland' },
  { value: 'SA', label: 'South Australia' },
  { value: 'TAS', label: 'Tasmania' },
  { value: 'VIC', label: 'Victoria' },
  { value: 'WA', label: 'Western Australia' },
  { value: 'ACT', label: 'Australian Capital Territory' },
  { value: 'NT', label: 'Northern Territory' },
];

export const profession = [
  { value: 'Advanced Trainee', label: 'Advanced Trainee' },
  { value: 'General Practitioner', label: 'General Practitioner' },
  { value: 'Nurse Practitioner', label: 'Nurse Practitioner' },
  { value: 'Registrar', label: 'Registrar' },
  { value: 'Specialist', label: 'Specialist' },
];

export const specialisation = [
  { value: 'Addiction Medicine (FAChAM)', label: 'Addiction Medicine (FAChAM)' },
  { value: 'Addiction Medicine (FAP)', label: 'Addiction Medicine (FAP)' },
  { value: 'Addiction medicine (OTP prescriber)', label: 'Addiction medicine (OTP prescriber)' },
  { value: 'Anaesthesia', label: 'Anaesthesia' },
  { value: 'Custodial Health', label: 'Custodial Health' },
  { value: 'Cardiology', label: 'Cardiology' },
  { value: 'Endocrinology', label: 'Endocrinology' },
  { value: 'Gastroenterology', label: 'Gastroenterology' },
  { value: 'General Practice', label: 'General Practice' },
  { value: 'Geriatric Medicine ', label: 'Geriatric Medicine' },
  { value: 'Haematology', label: 'Haematology' },
  { value: 'Immunology', label: 'Immunology' },
  { value: 'Infectious Disease', label: 'Infectious Disease' },
  { value: 'Nephrology', label: 'Nephrology' },
  { value: 'Neurology', label: 'Neurology' },
  { value: 'Oncology', label: 'Oncology' },
  { value: 'Pain Medicine', label: 'Pain Medicine' },
  { value: 'Psychiatry', label: 'Psychiatry' },
  { value: 'Respiratory Medicine', label: 'Respiratory Medicine' },
  { value: 'Sexual Health', label: 'Sexual Health' },
];

export const specialistType = [
  { value: 'Consultant', label: 'Consultant' },
  { value: 'Advanced Trainee', label: 'Advanced Trainee' },
  { value: 'Registrar', label: 'Registrar' },
];

export const additonalContactRole = [
  { value: 'Pharmacist', label: 'Pharmacist' },
  { value: 'Nurse', label: 'Nurse' },
  { value: 'Consultant', label: 'Consultant' },
  { value: 'Advanced Trainee', label: 'Advanced Trainee' },
  { value: 'Registrar', label: 'Registrar' },
  { value: 'Other', label: 'Other' },
];

export const treatmentSites = [
  { value: 'Westmead Hospital', label: 'Westmead Hospital' },
  { value: 'St Paul Hospital', label: 'St Paul Hospital' },
]

export const durationOfInitialTreatment = [
  { value: 'Days: Less than 7', label: 'Days: Less than 7' },
  { value: 'Weeks: 1 (7 days)', label: 'Weeks: 1 (7 days)' },
  { value: 'Weeks: 2-3', label: 'Weeks: 2-3' },
  { value: 'Months: 1-2', label: 'Months: 1-2' },
  { value: 'Months: 3-5', label: 'Months: 3-5' },
  { value: 'Months: 6-11', label: 'Months: 6-11' },
  { value: 'Years: 1-2', label: 'Years: 1-2' },
  { value: 'Years: 3-4', label: 'Years: 3-4' },
  { value: 'Years: 5+', label: 'Years: 5+' },
]