import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import moment from 'moment'

import Navigation from "../../components/Navigation";
import { ExtendedPatientDetailBox, ExtendedTreatmentDetailBox } from "../../components/DetailBox"

import getQueryParams from "utils/getQueryParams";
import Warning from "../../components/Warning";
import { requestPatchUser } from 'modules/user/actions';

import getCurrentDate from "utils/getCurrentDate"

function mapStateToProps(state, ownProps) {
  let queryParams = getQueryParams();
  let id = queryParams.patientId || ownProps.location.data && ownProps.location.data.patientId;

  let patient;
  if (id) {
    patient = state.user.patients && state.user.patients.filter(patient => patient.id == id)[0];
  } else {
    patient = null;
  }

  return {
    patient
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser
  }, dispatch);
}

class ConfirmOrder extends Component {

  submit(results) {
    return new Promise((resolve, reject) => {

      let shipmentDate = moment.utc(this.props.patient.shipmentDate);
      let currentDate = getCurrentDate();
      if (currentDate.hour() >= 12) {
        currentDate.add(1, 'd');
      }
      if (shipmentDate.isBefore(currentDate, 'day')) {
        return reject(new SubmissionError({
          _error: "Invalid shipment date"
        }));        
      }

      let data = {
        patient: {
          id: this.props.patient.id,
          orderConfirmedDate: this.props.patient.shipmentDate,
          confirmationTime: moment().format('DDMMYY HH:mm')
        }
      }

      if (this.props.isOnboarding) {
        data.patient.state = 'active';
        data.patient.initialAdministrationDate = this.props.patient.administrationDate;
        if (this.props.patient.additional8mgShipmentDate) {
          data.patient.additional8mgOrderConfirmedDate = moment(this.props.patient.additional8mgShipmentDate).format('YYYY-MM-DD');
          data.patient.additional8mgOrderConfirmationTime = moment().format('DDMMYY HH:mm');
          data.patient.totalAdditional8mgUnits = 1;
        }
      }

      this.props.requestPatchUser({
        data,
        callback: (result) => {
          if (!result.error) {
            this.props.history.push('/myremedy/patients/');
            return resolve();
          } else {
            return reject(new SubmissionError({
              _error: result.error.message
            }));
          }
        }
      });
    });
  }

  render() {

    if (!this.props.patient) {
      return "";
    }

    if (this.props.patient.shipmentDate && this.props.patient.orderConfirmedDate === this.props.patient.shipmentDate) {
      return (
        <div className="content">
          <h2>Your order has been submitted</h2>
        </div>
      );
    }

    let patientDetailsPath = this.props.isOnboarding ? "/myremedy/patients/onboarding-enrol-patient/?patientId=" + this.props.patient.id : null;
    let treatmentDetailsPath = this.props.isOnboarding ? "/myremedy/patients/onboarding-enrol-patient/?patientId=" + this.props.patient.id : null;

    return (
      <div className="content">
        <h1>Confirm order</h1>
        <p>Please review the details below before confirming the order.</p>
        <h2>Patient details</h2>
        <ExtendedPatientDetailBox patient={this.props.patient} location={patientDetailsPath} />

        <h2>MyRemedy treatment</h2>
        <ExtendedTreatmentDetailBox patient={this.props.patient} show8mg={this.props.isOnboarding} location={(this.props.isOnboarding?"/myremedy/patients/onboarding-drug-order/?patientId=":"/myremedy/patients/modify-order/?patientId=") + this.props.patient.id} />
        <p>You will receive notification to confirm ongoing supply 3 business days before the next order is due to ship; if you anticipate you will be unavailable at this time, you can sign in to this portal and confirm manually up to one week in advance. To ensure there are no disruptions, <strong>you must</strong> confirm continuing treatment within 48 hours of notification.</p>
        {this.props.error && <Warning>
          <p>As it is after 12pm your shipment date is no longer valid. Please modify your administration date under MyRemedy treatment to correct this.</p>
          {/* <p>As it is after 12pm Mon-Wed (AEST) shipping has been adjusted to 3 business days, therefore the administration date has been changed from <strong>06/01/2019</strong> to <strong>07/01/2019</strong>. You may modify the administration date or click continue to accept the change. </p> */}
        </Warning>
        }
        <form onSubmit={this.props.handleSubmit(results => this.submit(results))} autoComplete="off">
          {this.props.isOnboarding ?
            <Navigation secondaryText="Back" secondaryLink={"/myremedy/patients/onboarding-drug-order/?patientId=" + this.props.patient.id} primaryText="Confirm" primaryLink="" />
          :
            <Navigation secondaryText="Back" secondaryLink={"/myremedy/patients"} primaryText="Confirm" primaryLink="" />
          }
        </form>
      </div>
    )
  }
}

ConfirmOrder = reduxForm({
  form: 'ConfirmOrder' // a unique name for this form
})(ConfirmOrder);

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmOrder);