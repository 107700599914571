import React, { Component } from "react";
import Sidebar from "./components/Sidebar"
import { reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Select, Checkbox } from "components/Forms"

import { NavLink } from "react-router-dom";
import NavButton from "components/NavButton";
import { openModal } from 'modules/modals/actions';

import { requestPatchUser } from '../../../modules/user/actions'
import { additonalContactRole } from "utils/selectValues"
import filterInitialValues from 'utils/filterInitialValues'

import { _required, _email, _mobile } from "utils/validation"
let required = _required();
let email = _email();
let mobile = _mobile();

let requiredGroup = (value, allValues) => {
  let exists = false;
  if (!allValues.user || !allValues.user.myremedy) return undefined;

  for (let val of Object.keys(allValues.user.myremedy).filter(key=>key.indexOf('additionalContact') !== -1)) {
    if (allValues.user.myremedy[val] !== undefined && allValues.user.myremedy[val] !== '') {
      exists = true;
    }
  }

  if (!exists) return undefined;
  return ((value && !(value.length === 0)) || typeof value === 'number' ? undefined : 'This field is required');
}

function mapStateToProps(state) {
  return {
    user: state.user,
    initialValues: state.user.treatmentSites ? {
      user: state.user
    }:null,
    fields: (state.form.Settings && state.form.Settings.registeredFields) || {},
    treatmentSite: state.form.Settings && state.form.Settings.values ? state.form.Settings.values.user.myremedy.treatmentSite : null,
    treatmentSites: state.user.treatmentSites ? state.user.treatmentSites.filter(site=>site.State === state.user.state).map(site=>({value:site.id, label:site.name})) : []
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser,
    openModal
  }, dispatch);
}

class Settings extends Component {

  submit(_results) {
    return new Promise((resolve, reject) => {
      let results = filterInitialValues(_results, this.props.fields, this.props.initialValues);
      if (!results.hasChanged) {
        this.props.openModal("ProductProfileUpdated");
        return resolve();
      }

      this.props.requestPatchUser({
        data: results.user,
        callback: (result) => {
          if (!result.error) {
            this.props.openModal("ProductProfileUpdated");
            return resolve();
          } else {
            return reject(new SubmissionError({
              _error: result.error.message
            }));
          }
        }
      });
    });
  }

  submitWrapper(e) {
    let errors = this.props.handleSubmit(results => this.submit(results))(e);

    if (errors) {
      setTimeout(() => {
        if (window.$('.error').length) {
          window.$('html, body').animate({
              scrollTop: window.$('.error').offset().top - 200
          }, 200);
        }
      }, 10);
    }
    return false;
  }

  render() {
    const communications = [
      { value: 'email', label: this.props.user.email },
      { value: 'mobile-number', label: this.props.user.mobileNumber },
    ];

    return (
      <main className={"dashboard content-page " + this.props.type}>
        <Sidebar />
        <div className="content">
          <form onSubmit={(e)=>this.submitWrapper(e)} autoComplete="off">
            <input hidden autoComplete="off" />
            <h1>MyRemedy profile settings</h1>
            <h2>Drug shipment and urgent program updates</h2>
            <p>Please select your preferred option for notifications. For urgent communications, we will use both email and mobile numbers as required.</p>
            <Checkbox styling="float" name="user.myremedy.communications" options={communications} validate={[required]} />
            <p>Incorrect details? Update your <NavLink to="/my-account/">MedAX account</NavLink>.</p>
            <h2>Treatment site</h2>
            <Select styling="float l" name="user.myremedy.treatmentSite" type="text" title="Treatment site" options={[...this.props.treatmentSites, {value:-1, label:'My site is not listed'}]} validate={[required]} />
            {(this.props.treatmentSite === null || this.props.treatmentSite === -1) &&
              <Field styling="float r" name="user.myremedy.treatmentSiteOther" type="text" title="Other treatment site name" validate={[required]} />
            }
            {/* <Field styling="float r" name="user.myremedy.prescriberNumber" type="text" title="Prescriber number" validate={[required]} /> */}
            <h2>Additional contact</h2>
            <p>The additional contact must be a member of the healthcare team at the approved site who can receive updates on patient drug shipments (this person will not have access to the MedAX portal).</p>
            <Field styling="float l" name="user.myremedy.additionalContact_firstName" type="text" title="First name" validate={[requiredGroup]} />
            <Field styling="float r" name="user.myremedy.additionalContact_lastName" type="text" title="Last name" validate={[requiredGroup]} />
            <Field styling="float l" name="user.myremedy.additionalContact_emailAddress" type="text" title="Email address" autoCapitalize="none" autoCorrect="none" validate={[requiredGroup, email]} />
            <Field styling="float r" name="user.myremedy.additionalContact_mobileNumber" type="tel" title="Mobile number" validate={[requiredGroup, mobile]} />
            <Select isClearable styling="float l" name="user.myremedy.additionalContact_role" type="text" title="Role" options={additonalContactRole} validate={[requiredGroup]} />
            <Field styling="float r" name="user.myremedy.additionalContact_specifyRole" type="text" title="Specify role" validate={[requiredGroup]} />

            <div className="navigation-profile-settings">
              <div className="primary">
                <button className="button-type-1">Save updates</button>
              </div>
              <div className="secondary">
                <NavButton to="/myremedy/settings/deregister-profile" className="button-type-3">De-register profile</NavButton>
              </div>
            </div>
          </form>
        </div>
      </main>
    )
  }
}

Settings = reduxForm({
  form: 'Settings' // a unique name for this form
})(Settings);

export default connect(mapStateToProps, mapDispatchToProps)(Settings);