import React, { Component } from "react";

import Navigation from "./components/Navigation";
import OnboardingContainer from "./components/OnboardingContainer";

class Step2 extends Component {
  render() {
    return (
      <OnboardingContainer step="2">

        <h1>Privacy Statement</h1>
        <p>Company Privacy Statemen will appear here.</p>
       
        <Navigation step={2} secondaryText="Back" secondaryLink="/myremedy/setup/step-1/" primaryText="Next" primaryLink="/myremedy/setup/step-3" />

      </OnboardingContainer>
    )
  }
}

export default Step2;