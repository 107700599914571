import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import Sidebar from "../components/Sidebar"

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

class OrderExtraOutStock extends Component {
  render() {

    return (
      <main className={"dashboard content-page " + this.props.type}>
        <Sidebar />
        <div className="content">
          <h1>Order MyRemedy Weekly 8 mg</h1>
          <p>There is currently insufficient stock of MyRemedy Weekly 8 mg available at your site for dose adjustments as required. If you have questions, or believe additional stock is required, please contact the PFP admin.</p>
        </div>
      </main>
    )
  }
}

OrderExtraOutStock = reduxForm({
  form: 'Step4' // a unique name for this form
})(OrderExtraOutStock);

export default connect(mapStateToProps, mapDispatchToProps)(OrderExtraOutStock);