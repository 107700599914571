import React, { Component } from "react";
import Header from "components/Header";
import CompanyMenu from "./components/CompanyMenu";
import ExternalLink from "components/ExternalLink";

class PrivacyStatement extends Component {
    render() {
        return (
            <main className={"company " + this.props.type}>
            <CompanyMenu />
                <div className="content">
                    <div className="wrapper-w880">
                        <h1>MedAX Privacy Statement</h1>
                        <p>The following policy applies to all data collected to access <ExternalLink className="blue" href="http://www.medax.net.au" target="_blank">www.medax.net.au</ExternalLink>. Programs hosted on MedAX collect data on behalf of sponsor companies and may be administered by third‐party organisations. Please review the privacy policy and terms and conditions relating to each program prior to registering.</p>

                        <h2>Collection and purpose</h2>

                        <p>This website allows for direct input of Personal Information. In addition, this website makes use of ‘cookies’ which are small text files that are stored in the visitor's local browser cache. This enables recognition of the visitor's browser to optimise the website and simplify its use. Most browsers are set up to accept these cookies automatically, however you can deactivate the storing of cookies or adjust your browser to inform you before the cookie is stored on your computer. Data collected via cookies will not be used to determine the personal identity of the website visitor.</p>

                        <p>The purpose for which we collect personal data is to ensure eligibility to access this website, and where you have registered your interest in receiving updates about new programs, we will use your information to send you this information; where you are participating in a Product Familiarisation or Patient Access Program, we will use your information to confirm registration with the program and to effectively administer the program, including drug dispatch, patient discontinuation and adverse event reporting follow‐up.</p>

                        <p>We may disclose information about you in the course of any of the uses described above, including to related businesses and third‐party service providers for routine business purposes, such as order delivery, marketing, hosting, data processing and validation, data storage or archiving, printing and mailing. We will ensure that we enter into appropriate contractual provisions with service providers to safeguard your privacy.</p>

                        <p>Should we now or in the future use a third‐party Customer Database Provider to supply it with a syndicated database of healthcare professionals and their practices, we will be required to keep the database updated and may disclose some information about healthcare professionals and their practices to its Customer Database Provider. This information will be limited to professional information about healthcare professionals and their practices. The information is used for commercial purposes and the Customer Database Provider makes that information available to all parties who also have access to its database, including pharmaceutical companies.</p>

                        <h2>Data security</h2>

                        <p>We use technical and organisational security precautions to protect your data from misuse, interference or loss and from unauthorised access, modification or disclosure. Any Personal Information that is provided through this website will be encrypted in transit to prevent its possible misuse by third parties. Our security procedures are continuously revised based on new technological developments.</p>

                        <p>In the event of an actual or suspected data breach, we will follow the procedures outlined in our Data Breach Response Plan.</p>

                        <p>We will contact you if you have been personally impacted by an Eligible Data Breach.</p>

                        <h2>Data retention</h2>

                        <p>We will delete from our records Personal Information which is no longer required.</p>

                        <h2>Data access and correction</h2>

                        <p>You may request access to Personal Information we hold about you at any time. If you believe your Personal Information is inaccurate, out of date, incomplete, irrelevant or misleading, you may request to have it corrected.</p>

                        <h2>How to contact us about privacy</h2>

                        <p>Your privacy and transparency about how your data is used is important to us. If you have questions about the information we collect, you can email <a className="blue" href="mailto:privacyofficer@nof1solutions.com.au">privacyofficer@nof1solutions.com.au</a> or call N of One Solutions on <a className="blue" href="tel:0242583595">02 4258 3595.</a></p>
                    </div>
                </div>
            </main>
        )
    }
}

export default PrivacyStatement;