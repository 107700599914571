import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NavButton from "components/NavButton";

import { reduxForm, SubmissionError } from 'redux-form';
import { Field, Select, Checkbox } from "components/Forms"
import { requestPatchUser } from '../../modules/user/actions'
import filterInitialValues from 'utils/filterInitialValues'
import { formValueSelector } from 'redux-form';

import { title, state, profession, specialisation, specialistType, boolean } from "utils/selectValues"
import { _required } from "utils/validation"
let required = _required();

function mapStateToProps(state) {
  return {
    initialValues: {
      user: state.user
    },
    fields: (state.form.Profile && state.form.Profile.registeredFields) || {}
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser
  }, dispatch);
}

class Profile extends Component {

  submit(_results) {
    return new Promise((resolve, reject) => {
      let results = filterInitialValues(_results, this.props.fields, this.props.initialValues);
      if (!results.hasChanged) {
        this.props.history.push("/my-account/profile-updated");
        return resolve();
      }

      results.user.profileUpdated = 1;

      this.props.requestPatchUser({
        data: results.user,
        callback: (result) => {
          if (!result.error) {
            this.props.history.push("/my-account/profile-updated");
            return resolve();
          } else {
            return reject(new SubmissionError({
              _error: result.error.message
            }));
          }
        }
      });
    });
  }


  render() {

    return (
      <main className={"content-page profile"}>
        <div className="wrapper-w731">
          <div className="content">
            <h1>My account</h1>
            <form onSubmit={this.props.handleSubmit(results => this.submit(results))} autoComplete="off">
              <input hidden autoComplete="off" />
              <Select styling="float l" name="user.title" type="text" title="Title" options={title} validate={[required]} />
              <Field styling="float l" name="user.firstName" type="text" title="First name" validate={[required]} />
              <Field styling="float r" name="user.lastName" type="text" title="Last name" validate={[required]} />

              <span className="field-button l">
                <Field styling="float" name="user.email" type="email" title="Your email address" disabled />
                <NavButton to="/my-account/change-email" className="button-type-2">Change email</NavButton>
              </span>
              <span className="field-button r">
                <Field styling="float" name="user.mobileNumber" type="tel" title="Your mobile number" disabled />
                <NavButton to="/my-account/change-mobile" className="button-type-2">Change mobile</NavButton>
              </span>

              <hr />

              <span className="field-button l">
                <Field styling="float" name="user.password" type="password" title="Your password" disabled />
                <NavButton to="/my-account/change-password" className="button-type-2">Change password</NavButton>
              </span>

              <hr />

              <Field styling="float l" name="user.ahpra" type="text" title="Your AHPRA Number" validate={[required]} disabled />
              <Select styling="float r" name="user.state" type="text" title="State" options={state} validate={[required]} disabled />
              <Select styling="float l" name="user.meta.specialisation" type="text" title="Your specialisation" options={specialisation} isMulti validate={[required]} />

              <Select styling="float r" name="user.profession" type="text" title="Profession" options={profession} validate={[required]} disabled />
              
              <h2>MedAX Preferences</h2>
              <Checkbox radio styling="float" name="user.meta.alertPreferences" title="Please alert me to other Product Familiarisation Programs?" options={boolean} />

              <div className="navigation">
                <NavButton className="button-type-2" to={""}>Back</NavButton>
                <button type="submit" className="button-type-1">Save</button>
              </div>

            </form>
          </div>
        </div>
      </main>
    )
  }
}

Profile = reduxForm({
  form: 'Profile' // a unique name for this form
})(Profile);

const selector = formValueSelector('Profile')
Profile = connect(
  state => {
    // can select values individually
    const profession = selector(state, 'user.profession');

    return {
      profession
    }
  }
)(Profile);

export default connect(mapStateToProps, mapDispatchToProps)(Profile);