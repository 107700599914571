import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError, change } from 'redux-form';
import { Field, Select, Checkbox, UnsavedChangesPrompt } from "components/Forms"
import moment from 'moment-timezone'
import { weeklyDrugs, monthlyDrugs } from "utils/selectValues";

import Navigation from "../../components/Navigation";
import Constantina from "../../components/Constantina";

import { PatientDetailBox } from "../../components/DetailBox"
import getQueryParams from "utils/getQueryParams";
import { requestPatchUser } from 'modules/user/actions'
import filterInitialValues from 'utils/filterInitialValues'

import getCurrentDate from "utils/getCurrentDate"
import deliveryTimes from "utils/deliveryTimes"

import { _required, _email } from "utils/validation"
let required = _required();
let requiredDate = _required("Please enter a valid date in the format: DD/MM/YY.");

const dateFormatString = 'ddd Do MMMM YYYY';

let administrationDateValidation = (value, allValues) => {
  
  let shipmentDate = moment(allValues.user.patient.shipmentDate, dateFormatString);
  let currentDate = getCurrentDate().tz('Australia/Sydney');
  console.log(currentDate.hour());
  if (currentDate.hour() >= 12) {
    currentDate.add(1, 'd');
  }
  if (shipmentDate.isBefore(currentDate, 'day')) {
    return "Cannot ship in time for given administration date";
  } else {
    return undefined;
  }
};

function mapStateToProps(state, ownProps) {

  let queryParams = getQueryParams();
  let id = queryParams.patientId || ownProps.location.data && ownProps.location.data.patientId;

  let patient, treatmentSite;
  if (id) {
    patient = state.user.patients && state.user.patients.filter(patient => patient.id == id)[0];
    treatmentSite = (patient && patient.treatmentSiteId && state.user.treatmentSites.find(site => site.id === patient.treatmentSiteId)) || null;
  } else {
    patient = treatmentSite = null;
  }

  return {
    patient,

    initialValues: {
      user: {
        patient
      }
    },
    include8mg: treatmentSite && treatmentSite.allowAdditional,
    isRural: treatmentSite && treatmentSite.delivery === 'rural',
    fields: (state.form.DrugOrderDetails && state.form.DrugOrderDetails.registeredFields) || {},
    shipmentDate: (state.form.DrugOrderDetails && state.form.DrugOrderDetails.values && state.form.DrugOrderDetails.values.user.patient.shipmentDate) || '',
    administrationDate: (state.form.DrugOrderDetails && state.form.DrugOrderDetails.values && state.form.DrugOrderDetails.values.user.patient.administrationDate) || '',
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser,
    change
  }, dispatch);
}

class DrugOrderDetails extends Component {

  state = {
    deliveryDate: ''
  }

  submit(_results) {
    return new Promise((resolve, reject) => {
      let shipmentDate = moment(_results.user.patient.shipmentDate, dateFormatString);
      _results.user.patient.shipmentDate = shipmentDate.format('YYYY-MM-DD');

      if (!this.props.isOnboarding && this.props.patient.administrationDate && moment(this.props.patient.administrationDate).isAfter(moment(_results.user.patient.administrationDate), 'd')) {
        return reject(new SubmissionError({
          user: {
            patient: {
              administrationDate: "New administration date must be after the old one."
            }
          }
        }));
      }
      _results.user.patient.administrationDate = moment(_results.user.patient.administrationDate).format('YYYY-MM-DD');


      let fields = { ...this.props.fields };

      if (this.props.isOnboarding) {
        if (this.props.include8mg && (_results.user.patient.orderQuantity < 32 || (_results.user.patient.orderQuantity > 32 && _results.user.patient.orderQuantity < 128))) {
          _results.user.patient.additional8mgUnitsOrdered = 1;
          _results.user.patient.additional8mgShipmentDate = shipmentDate.format('YYYY-MM-DD');
        } else {
          _results.user.patient.additional8mgUnitsOrdered = null;
          _results.user.patient.additional8mgShipmentDate = null;
        }
        fields['user.patient.additional8mgUnitsOrdered'] = {};
        fields['user.patient.additional8mgShipmentDate'] = {};
      }

      let results = filterInitialValues(_results, fields, this.props.initialValues);

      if (!this.props.isOnboarding && this.props.patient && !this.props.patient.oldDose &&  moment(_results.user.patient.administrationDate).isBefore(shipmentDate, 'd')) {
        results.user.patient.oldDose = this.props.patient.orderQuantity;
      }

      let redirectPath = "";
      if (this.props.isOnboarding) {
        redirectPath = "/myremedy/patients/onboarding-confirm-order/?patientId=" + this.props.patient.id;
      } else {
        let currentDate = getCurrentDate();
        currentDate.add(7, 'days');
        if (currentDate.isAfter(_results.user.patient.shipmentDate)) {
          redirectPath = "/myremedy/patients/confirm-order/?patientId=" + this.props.patient.id;
        } else {
          redirectPath = "/myremedy/patients";
        }
      }

      if (!results.user.patient || results.user.patient.length === 0) {
        this.props.history.push({
          pathname: redirectPath,
          data: this.props.patient.id
        });
      }

      results.user.patient.id = this.props.patient.id;

      this.props.requestPatchUser({
        data: results.user,
        callback: (result) => {
          if (!result.error) {

            this.props.history.push({
              pathname: redirectPath,
              // data: {
              //   patientId: result.id
              // }
            });
            return resolve();
          } else {
            return reject(new SubmissionError({
              _error: result.error.message
            }));
          }
        }
      });
    });
  }

  setShipmentDate(administrationDate) {

    let adminDate = moment(administrationDate.value || administrationDate);
    let oldAdminDate = moment(this.props.patient.administrationDate);

    if (this.props.patient) {
      let currentShipmentDate = moment(this.props.patient.shipmentDate);
      if (oldAdminDate.isBefore(currentShipmentDate, 'd')) {
        if (this.props.patient.oldDose ? this.props.patient.oldDose < 64 : this.props.patient.orderQuantity < 64) {
          adminDate.add(7, 'days');
          if (oldAdminDate.add(7, 'days').isBefore(currentShipmentDate, 'd')) {
            adminDate.add(7, 'days');
          }
        } else {
          adminDate.add(4, 'weeks');
        }
      }
    }
    let currentHour = new Date().getHours();
    let businessDays = (this.props.isRural ? deliveryTimes.rural: deliveryTimes.urban) + 1;

    let shipmentDate = adminDate;
    while (businessDays > 0 || shipmentDate.day() >= 5 || shipmentDate.day() === 0) {
      shipmentDate.subtract(1, 'd');
      if (!(shipmentDate.day() === 0 || shipmentDate.day() === 6)) {
        if (businessDays === 2) {
          let deliveryDate = shipmentDate.format('DD/MM/YY');
          this.setState(state=>({...state, deliveryDate}))
        }
        businessDays -= 1;
      }
    }

    this.props.change('user.patient.shipmentDate', shipmentDate.format(dateFormatString));
  }

  submitWrapper(e) {
    let errors = this.props.handleSubmit(results => this.submit(results))(e);

    if (errors) {
      setTimeout(() => {
        if (window.$('.error').length) {
          window.$('html, body').animate({
              scrollTop: window.$('.error').offset().top - 200
          }, 200);
        }
      }, 10);
    }
    return false;
  }

  render() {
    let patient = this.props.patient;

    if (!patient) {
      return "No patient selected";
    }

    if (patient.shipmentDate && patient.orderConfirmedDate === patient.shipmentDate) {
      return (
        <div className="content">
          <h2>Your order has been submitted</h2>
        </div>
      );
    }

    return (
      <div className="content">
        <form onSubmit={(e)=>this.submitWrapper(e)} autoComplete="off">
          <h1>{this.props.isOnboarding ? '' : 'Modify '}Drug order</h1>
          <p>Please review the full Product Information (PI) for MyRemedy before prescribing. Full PIs can be accessed <NavLink onClick={()=>{alert("Link disabled for demo."); return false}}>here</NavLink>.</p>

          <h2>Patient</h2>
          <PatientDetailBox patient={patient} />

          <h2>Administration date</h2>
          <p>Please select your patient’s date of administration. </p>

          <Select onChange={(value) => this.setShipmentDate(value)} validate={[requiredDate, administrationDateValidation]} date disableCalendar isClearable styling="float l" name="user.patient.administrationDate" type="text" title="Administration date (DD/MM/YY)" noOptionsMessage={() => null} />
          <Field disabled styling="float r" name="user.patient.shipmentDate" type="text" title={(this.props.isOnboarding?"":"Next ") + "Shipment date"} />

          {this.state.deliveryDate && this.props.isOnboarding &&
          <div className="shipment-infographic">
            <div className="top">
              <div className="left">
                <img src={require('../../../../../img/components/icon_truck.png')} alt="truck" />
              </div>
              <div className="center">
                <img src={require('../../../../../img/components/icon_estimated.png')} alt="estimated" />
              </div>
              <div className="right">
                <img src={require('../../../../../img/components/icon_administration.png')} alt="administration" />
              </div>
            </div>
            <div className="middle">
              <div className="left">
              </div>
              <div className="center">
              </div>
              <div className="right">
              </div>
              <hr />
            </div>
              <div className="bottom">
                <div className="left">
                  <div className="label">
                    <h3>Shipment sent</h3>
                    <p>{moment(this.props.shipmentDate, dateFormatString).format('DD/MM/YY')}</p>
                  </div>
                </div>
                <div className="center">
                  <div className="label">
                    <h3>Estimated delivery</h3>
                    <p>{this.state.deliveryDate}</p>
                  </div>
                </div>
                <div className="right">
                  <div className="label">
                    <h3>Administration</h3>
                    <p>{moment(this.props.administrationDate).format('DD/MM/YY')}</p>
                  </div>
                </div>
              </div>
          </div>
          }

          <p className="small">For orders placed <strong>before 12pm Mon-Thu </strong>(AEST) earliest delivery will be <strong>+{(this.props.isRural ? deliveryTimes.rural: deliveryTimes.urban)}  business day</strong>.</p>
          <p className="small">For orders placed <strong>after 12pm Mon-Wed </strong>(AEST) earliest delivery will be <strong>+{(this.props.isRural ? deliveryTimes.rural: deliveryTimes.urban) + 1} business days</strong>.</p>
          <p className="small">For orders placed <strong>after 12pm Thu or anytime Fri-Sun</strong> (AEST) earliest delivery will be the <strong>following {this.props.isRural ? deliveryTimes.ruralAfterThurs : deliveryTimes.urbanAfterThurs}</strong>.</p>
          <p className="extra-small grey">Note: Administration date must be at least 1 day after earliest delivery date.</p>

          <h2>Order quantity</h2>
          <div className="column-1-2column">
            <h3>MyRemedy Weekly</h3>
            <Checkbox validate={[required]} radio title="You will receive 2 doses per order" styling="float l" name="user.patient.orderQuantity" options={weeklyDrugs} />
          </div>

          <div className="column-2-2column">
            <h3>MyRemedy Monthly</h3>
            <Checkbox validate={[required]} radio title="You will receive 1 dose per order" styling="float r" name="user.patient.orderQuantity" options={monthlyDrugs} />
          </div>
          
          <p><strong>Please note: </strong>Any changes made after the order is submitted will take effect with the next scheduled shipment.</p>

          {/* <Warning>
            <p>As it is after 12pm Mon-Wed (AEST) shipping has been adjusted to 3 business days, therefore the administration date has been changed from <strong>06/01/2019</strong> to <strong>07/01/2019</strong>. You may modify the administration date or click continue to accept the change. </p>
          </Warning> */}

          {this.props.isOnboarding ?
            <Navigation secondaryText="Back" secondaryLink={"/myremedy/patients/onboarding-enrol-patient/?patientId=" + this.props.patient.id} primaryText="Continue" primaryLink="/patients/onboarding-confirm-order" />
            :
            <Navigation secondaryText="Back" secondaryLink={"/myremedy/patients"} primaryText="Save" primaryLink="/patients" />
          }
          <UnsavedChangesPrompt when={!(this.props.pristine || this.props.submitting)} />
        </form>
      </div>
    )
  }
}

DrugOrderDetails = reduxForm({
  form: 'DrugOrderDetails' // a unique name for this form
})(DrugOrderDetails);

export default connect(mapStateToProps, mapDispatchToProps)(DrugOrderDetails);
