import React, { Component } from "react";
import Sidebar from "./components/Sidebar"
import PdfDocument from "./components/PdfDocument"

class Resources extends Component {
    render() {
        return (
            <main className={"dashboard content-page " + this.props.type}>
                <Sidebar />
                <div className="content">
                  <h1>Resources</h1>
                  <h2>Clinician resources</h2>
                  <PdfDocument documentName="PFP guide" link={''} />
                  <PdfDocument documentName="Education module" link={''} />
                  <PdfDocument documentName="MyRemedy Product Information" link={''} />

                  <h2>Patient resources</h2>
                  <PdfDocument documentName="Patient consent" link={''} />
                  <PdfDocument documentName="MyRemedy Consumer Medicines Information" link={''} />
                  <PdfDocument documentName="Patient Alert Card" link={''} />
                  <PdfDocument documentName="Patient Education Module" link={''} /> 
                </div>
            </main>
        )
    }
}

export default Resources;