import React, { Component } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { withRouter } from 'react-router-dom';

import Signup from "./accounts/Signup";
import Signin from "./accounts/Signin";
import ForgotPassword from "./accounts/ForgotPassword";
import SignupConfirmEmail from "./accounts/SignupConfirmEmail";
import SignupEnterMobile from "./accounts/SignupEnterMobile";
import SignupConfirmMobile from "./accounts/SignupConfirmMobile";
import ForgotPasswordConfirmPin from "./accounts/ForgotPasswordConfirmPin";
import ForgotPasswordChange from "./accounts/ForgotPasswordChange";
import SigninContinueRegistration from "./accounts/SigninContinueRegistration";
import SignupCompleteAccount from "./accounts/SignupCompleteAccount";
import SignupThanks from "./accounts/SignupThanks";
import ForgotPasswordAttempts from "./accounts/ForgotPasswordAttempts";
import TooManyPinAttempts from "./accounts/TooManyPinAttempts";
import TooManySMSAttempts from "./accounts/TooManySMSAttempts";

import About from "./company/About";
import PrivacyStatement from "./company/PrivacyStatement";
import TermsOfUse from "./company/TermsOfUse";

import PatientResources from "./product/dashboard/PatientResources";

class Routes extends Component {
  render() {
    console.log(this.props.signupStage);
    return (
      <Switch>
        {/* Accounts Start */}
        {/* <Route exact path="/" render={(props) => <Signin {...props} /> } /> */}
        <Route exact path="/login" render={(props) => <Signin {...props} />} />
        <Route exact path="/signup" render={(props) => <Signup {...props} />} />
        <Route exact path="/forgot-password" render={(props) => <ForgotPassword {...props} />} />
        <Route exact path="/forgot-password-attempts" render={(props) => <ForgotPasswordAttempts {...props} />} />
        <Route exact path="/too-many-pin-attempts" render={(props) => <TooManyPinAttempts {...props} />} />
        <Route exact path="/too-many-sms-attempts" render={(props) => <TooManySMSAttempts {...props} />} />

        {/* Company Start */}
        <Route exact path="/company/about" render={(props) => <About {...props} />} />
        <Route exact path="/company/privacy" render={(props) => <PrivacyStatement {...props} />} />
        <Route exact path="/company/terms" render={(props) => <TermsOfUse {...props} />} />

        <Route exact path="/forgot-password-confirm-pin" render={(props) => <ForgotPasswordConfirmPin {...props} />} />
        <Route exact path="/forgot-password-change" render={(props) => <ForgotPasswordChange {...props} />} />

        <Route exact path="/myremedy-patient-resources" render={(props) => <PatientResources type="patient" {...props} />} />

        {this.props.signupStage === undefined &&
          <Route
            path="/:id*"
            render={props => (
              <Redirect to={`/login?${window.location.search.split('?')[1] || ''}&next=${props.match.params.id || ''}`} />
            )}
          />
        }

        <Route exact path="/login-continue-registration" render={(props) => <SigninContinueRegistration {...props} />} />


        <Route exact path="/signup-confirm-email" render={(props) => <SignupConfirmEmail {...props} />} />
        {this.props.signupStage < 1 &&
          <Redirect to="/login-continue-registration" />
        }
        <Route exact path="/signup-enter-mobile" render={(props) => <SignupEnterMobile {...props} />} />
        <Route exact path="/signup-confirm-mobile" render={(props) => <SignupConfirmMobile {...props} />} />
        {this.props.signupStage < 2 &&
          <Redirect to="/login-continue-registration" />
        }
        <Route exact path="/signup-complete-account" render={(props) => <SignupCompleteAccount {...props} />} />
        {this.props.signupStage < 3 &&
          <Redirect to="/login-continue-registration" />
        }
        <Route exact path="/signup-thanks" render={(props) => <SignupThanks {...props} />} />
        {/* <Redirect to="/login" /> */}
        {/* <Route exact path="/" render={(props) => <Signin {...props} /> } /> */}

      </Switch>
    )
  }
};

export default withRouter(props => <Routes {...props} />);