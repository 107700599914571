import React, { Component } from "react";

import Sidebar from "../components/Sidebar"
import PatientProgressBar from "../components/PatientProgressBar";
import DrugOrderDetails from './components/DrugOrderDetails'

class OnboardingDrugOrder extends Component {
  render() {
    return (
      <main className={"dashboard content-page " + this.props.type}>
        <Sidebar />
        <PatientProgressBar step="2" />
        <DrugOrderDetails {...this.props} isOnboarding={true} />
      </main>
    )
  }
}

export default OnboardingDrugOrder;
