import React, { Component } from "react";
import NavButton from "components/NavButton";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { requestPatchUser } from 'modules/user/actions'

function mapStateToProps(state) {
  return {
    onboardingStep: state.user.myremedy && state.user.myremedy.onboardingStep
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser,
  }, dispatch);
}

class Navigation extends Component {

  goToNext = ()=>{
    if (!this.props.onboardingStep || this.props.onboardingStep <= this.props.step) {
      this.props.requestPatchUser({
        data: {
            myremedy: {
              onboardingStep: this.props.step + 1
            }
        }
      });
    }
    this.props.history.push(this.props.primaryLink);
  }

  render() {
    let primaryOnClick = this.props.primaryOnClick;

    if (!primaryOnClick) {
      primaryOnClick = ()=>this.goToNext();
    }

    return (
      <div className="navigation">
        {(this.props.secondaryText)?
          <NavButton className="button-type-2" to={this.props.secondaryLink}>{this.props.secondaryText}</NavButton>
        :""}
        <button className="button-type-1" onClick={primaryOnClick}>{this.props.primaryText}</button>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navigation));