import React, { Component } from "react";
import Header from "components/Header";
import CompanyMenu from "./components/CompanyMenu";
import ExternalLink from "components/ExternalLink"

class About extends Component {
    render() {
        return (
            <main className={"company " + this.props.type}>
            <CompanyMenu />
                <div className="content">
                    <div className="wrapper-w880">
                        <h1>About MedAX</h1>
                        <p>MedAX is an independent platform designed to support the implementation and management of medication access programs (MAPs), including product familiarisation programs (PFPs) and patient access programs.</p>

                        <p>Our goal is to facilitate access to, and familiarisation with, new medications by providing an online and mobile‐optimised platform that offers a streamlined user experience, secure data management, and functionality designed to meet the varied protocols of new products, whether they are prescribed in a specialist or primary care setting.</p>

                        <p>For healthcare providers, MedAX provides a single point of registration to access multiple programs that use the same easy-to-navigate interface. For pharmaceutical companies, MedAX provides the benefit of a secure, fully-tested site built on extensive MAP expertise. Using MedAX reduces program costs and development time and MedAX can integrate with company sites to provide healthcare professionals with a fully‐integrated user journey.</p>
                        
                        <h2>Contact us</h2>
                        <p>MedAX is administered by:</p>
                        <div className="no-of-one">
                            <div className="image">
                            <img src={require('../../img/medax/no_of_one_logo.png')} alt="no_of_one_logo"/>
                            </div>
                            <div className="description">
                                <p><strong>N of One Solutions Pty Ltd</strong></p>
                                <p><span className="grey">ABN 17 627 152 014</span></p>
                                <p>Suite 1, Enterprise 1 </p>
                                <p>University of Wollongong</p>
                                <p>Innovation Campus, Squires Way</p>
                                <p>North Wollongong 2500</p>
                                <p><strong>P</strong> 02 4258 3595</p>
                                <p><strong>E</strong> MedAX@nof1solutions.com.au </p>
                            </div>
                        </div>
                        <p>Powered and maintained by <ExternalLink className="blue" href="http://wax.com.au" target="_blank">Wax Interactive Pty Ltd.</ExternalLink></p>
                    </div>
                </div>
            </main>
        )
    }
}

export default About;