import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import { requestPatchUser } from '../../../modules/user/actions'

import Navigation from "./components/Navigation";
import OnboardingContainer from "./components/OnboardingContainer";
import { Field, Select, Checkbox } from "components/Forms"

import { additonalContactRole } from "utils/selectValues"
import filterInitialValues from 'utils/filterInitialValues'

import { _required, _email, _mobile } from "utils/validation"
let required = _required();
let email = _email();
let mobile = _mobile();

let requiredGroup = (value, allValues) => {
  let exists = false;
  if (!allValues.user || !allValues.user.myremedy) return undefined;

  console.log(JSON.stringify(allValues.user.myremedy));
  for (let val of Object.keys(allValues.user.myremedy).filter(key=>key.indexOf('additionalContact') !== -1)) {
    if (allValues.user.myremedy[val] !== undefined && allValues.user.myremedy[val] !== '') {
      exists = true;
    }
  }

  if (!exists) return undefined;
  return ((value && !(value.length === 0)) || typeof value === 'number' ? undefined : 'This field is required');
}


function mapStateToProps(state) {
  return {
    user: state.user,
    initialValues: {
      user: state.user
    },
    fields: (state.form.Step4 && state.form.Step4.registeredFields) || {},
    treatmentSites: state.user.treatmentSites ? state.user.treatmentSites.filter(site=>site.State === state.user.state).map(site=>({value:site.id, label:site.name})) : [],
    treatmentSite: state.form.Step4 && state.form.Step4.values ? state.form.Step4.values.user.myremedy.treatmentSite : -1
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser,
  }, dispatch);
}

class Step4 extends Component {

  submit(_results) {
    return new Promise((resolve, reject) => {

      let results = filterInitialValues(_results, this.props.fields, this.props.initialValues);
      if (!results.hasChanged) {
        this.props.history.push("/myremedy/setup/step-5");
        return resolve();
      }  
      results.user.myremedy.onboardingStep = 5;
      
      this.props.requestPatchUser({
        data: results.user,
        callback: (result) => {
          if (!result.error) {
            this.props.history.push("/myremedy/setup/step-5");
            return resolve();
          } else {
            return reject(new SubmissionError({
              _error: result.error.message
            }));
          }
        }
      });
    });
  }

  submitWrapper(e) {
    let errors = this.props.handleSubmit(results => this.submit(results))(e);

    if (errors) {
      setTimeout(() => {
        if (window.$('.error').length) {
          window.$('html, body').animate({
              scrollTop: window.$('.error').offset().top - 200
          }, 200);
        }
      }, 10);
    }
    return false;
  }

  render() {
    const { user, treatmentSite } = this.props;
    const communications = [
      { value: 'email', label: user.email },
      { value: 'mobile-number', label: user.mobileNumber },
    ];

    const authorisation = [
      { value: 'true', label: 'I confirm that I am currently authorised to prescribe relevent treatments in my state.' },
    ];

    let treatmentSiteOptions = [...this.props.treatmentSites];
    if (!treatmentSiteOptions.length) {
      treatmentSiteOptions = [{ value: -1, label: 'My site is not listed' }];
    } 

    return (
      <OnboardingContainer step="4">
        <form onSubmit={(e)=>this.submitWrapper(e)} autoComplete="off">
          <input hidden autoComplete="off" />
          <h1>MyRemedy profile settings</h1>
          <h2>Drug shipment and urgent program updates</h2>
          <p>Please select your preferred option for notifications. For urgent communications, we will use both email and mobile numbers as required.</p>
          <Checkbox styling="float" name="user.myremedy.communications" options={communications} validate={[required]} />
          <p>Incorrect details? Update your <NavLink to="/my-account/">MedAX account</NavLink>.</p>
          <h2>Treatment site</h2>
          <Select styling="float l" name="user.myremedy.treatmentSite" type="text" title="Treatment site" options={treatmentSiteOptions} validate={[required]} />
          {(treatmentSite === -1) &&
            <Field styling="float r" name="user.myremedy.treatmentSiteOther" type="text" title="Other treatment site name" validate={[required]} />
          }
          {/* <Field styling="float r" name="user.myremedy.prescriberNumber" type="text" title="Prescriber number" validate={[required]} /> */}
          <h2>Additional contact</h2>
          <Field styling="float l" name="user.myremedy.additionalContact_firstName" type="text" title="First name" validate={[requiredGroup]} />
          <Field styling="float r" name="user.myremedy.additionalContact_lastName" type="text" title="Last name" validate={[requiredGroup]} />
          <Field styling="float l" name="user.myremedy.additionalContact_emailAddress" type="email" title="Email address" autoCapitalize="none" autoCorrect="none" validate={[requiredGroup, email]} />
          <Field styling="float r" name="user.myremedy.additionalContact_mobileNumber" type="tel" title="Mobile number" validate={[requiredGroup, mobile]} />
          <Select isClearable styling="float l" name="user.myremedy.additionalContact_role" type="text" title="Role" options={additonalContactRole} validate={[requiredGroup]} />
          <Field styling="float r" name="user.myremedy.additionalContact_specifyRole" type="text" title="Role in PFP" />
          <p>The additional contact must be a member of the healthcare team at the approved site who can receive updates on patient drug shipments (this person will not have access to the MedAX portal).</p>
          <h2>Authorisation</h2>
          <Checkbox styling="float" name="authorisation" options={authorisation} validate={[required]} />

          <Navigation step={4} secondaryText="Back" secondaryLink="/myremedy/setup/step-3/" primaryText="Next"
            primaryOnClick={()=>{}} />
        </form>
      </OnboardingContainer>
    )
  }
}

Step4 = reduxForm({
  form: 'Step4', // a unique name for this form
})(Step4);

export default connect(mapStateToProps, mapDispatchToProps)(Step4);