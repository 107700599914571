import React, { Component } from "react";
import Sidebar from "./components/Sidebar"
import ExternalLink from "components/ExternalLink";

class ProductTermsAndConditions extends Component {
    render() {
        return (
            <main className={"dashboard content-page " + this.props.type}>
                <Sidebar />
                <div className="content">
                    <h1>MyRemedy PFP Terms & Conditions</h1>
                    <ExternalLink to="https://www.tga.gov.au/reporting-problems"/>
                    <ul>
                        <li>Program Terms and Conditions will appear here.</li>
                    </ul>
                </div>
            </main>
        )
    }
}

export default ProductTermsAndConditions;