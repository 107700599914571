import React, { Component } from "react";
import Sidebar from "./components/Sidebar"
import { NavLink } from "react-router-dom";

class ContactUs extends Component {
    render() {
        return (
            <main className={"dashboard content-page " + this.props.type}>
                <Sidebar />
                <div className="content">
                    <h1>Contact us</h1>
                    <p>Please review the full Product Information (PI) for both MyRemedy before prescribing. Full PI can be accessed <NavLink onClick={()=>{alert("Link disabled for demo."); return false}}>here</NavLink>.</p>

                    <h2>PFP Administrator</h2>
                    <p><strong>N of One Solutions Pty Ltd </strong><br/>
                    <strong className="light">Phone:</strong> <a href="tel:1800 995 888">1800 995 888</a> <br/> 
                    <strong className="light">Email:</strong> <a href="mailto:pfpadmin@nof1solutions.com.au">pfpadmin@nof1solutions.com.au</a></p>
                    <p>Please note operating hours are Monday through Friday between 9 am and 5 pm (AEST).</p>

                    <h2>Company Contact</h2>
                    <p>Company contact information to appear here</p>
                </div>
            </main>
        )
    }
}

export default ContactUs;