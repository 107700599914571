import React, { Component } from "react";
import { reduxForm, SubmissionError } from 'redux-form';
import { Checkbox } from "components/Forms"
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ExternalLink from "components/ExternalLink";

import Navigation from "./components/Navigation";
import OnboardingContainer from "./components/OnboardingContainer";
import { requestPatchUser } from '../../../modules/user/actions'

import { _required } from "utils/validation"
let required = _required();

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser,
  }, dispatch);
}

class Step5 extends Component {

  submit(_results) {
    return new Promise((resolve, reject) => {
      this.props.requestPatchUser({
        data: {
          myremedy: {
            onboardingStep: 6
          }
        },
        callback: (result) => {
          if (!result.error) {
            this.props.history.push("/myremedy/patients");
            return resolve();
          } else {
            return reject(new SubmissionError({
              _error: result.error.message
            }));
          }
        }
      });
    });
  }

  render() {
    return (
      <OnboardingContainer step="5">
        <h1>Educational material for healthcare professionals</h1>
        <h2>Introduction</h2>
        <ul>
          <li>Product Education to appear here.</li>
        </ul>
        <h2></h2>

        <form onSubmit={this.props.handleSubmit(results => this.submit(results))} autocomplete="off">
          <Checkbox styling="float" name="authorisation" options={[{ value: 'true', label: 'I have read the mandatory MyRemedy Weekly/Monthly education in its entirety, and understand the appropriate use of MyRemedy.' }]} validate={[required]} />
          <Navigation step={5} secondaryText="Back" secondaryLink="/myremedy/setup/step-4/" primaryOnClick={() => { }} primaryText="Next" primaryLink="/myremedy/patients" />
        </form>

      </OnboardingContainer>
    )
  }
}

Step5 = reduxForm({
  form: 'Step5', // a unique name for this form
})(Step5);

export default connect(null, mapDispatchToProps)(Step5);