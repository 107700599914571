import React, { Component } from "react";
import ContainerBox from "view/accounts/components/ContainerBox";
import NavButton from "components/NavButton";
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  onboardingStep: state.user.myremedy.onboardingStep
});


class ProfileUpdated extends Component {
  render() {
    let link = '/my-account';

    if (this.props.onboardingStep < 6) {
      link = '/';
    }
    return (
      <ContainerBox type="my-account account-profile-updated" title="Profile Updated" noLinks>
        <p>Profile has been successfully updated.</p>
        <img src={require('../../../img/accounts/signup_thanks.png')} alt="signup_thanks"/>

        <div className="navigation">
          <div className="secondary">
          </div>
          <div className="primary">
            <NavButton className="button-type-1" to={link}>Next</NavButton>
          </div>
        </div>
      </ContainerBox>
    )
  }
}

export default connect(mapStateToProps, null)(ProfileUpdated);