import React, { Component } from "react";
import NavButton from "components/NavButton";
import { NavLink } from "react-router-dom";

function regimeItem(size, regime, value) {
  return <li className={size}><span className="regime">{regime + ": "}</span><span className="empty"></span></li>
}

class PatientBoxLoading extends Component {
  render() {
    return (
      <div className="patient-box-loading">
        <div className="patient-box loading">
          <div className="patient-details">
            <div className="name"><span className="empty"></span></div>
            <div className="date"><span className="empty"></span></div>
            <div className="gender"><span className="empty"></span></div>
            <div className="status discontinued"><span className="empty"></span></div>
          </div>
          <div className="regime-details">
            <ul>
              {regimeItem("double", "site", "")}
              {regimeItem("single", "regime", "Weekly")}
              {regimeItem("single", "role", "Managing")}
              {regimeItem("single", "dose", "150mg x 5")}
              {regimeItem("single", "next administration", "02/01/2019")}
              {regimeItem("single", "additional myremedy weekly 8 mg", "5 units")}
              {regimeItem("single", "shipment date", "12/03/2019")}
            </ul>
            <div className="patient-navigation">
              <button className="modify-order button-type-2 discontinued">Modify order</button>
              <span className="additional-settings-wrapper">
                <button className="additional-settings button-type-2"></button>
              </span>
              <button className="primary-button button-type-2 discontinued">+ MyRemedy Weekly 8mg</button>
            </div>
          </div>
          <div className="patient-navigation">
            <button className="modify-order button-type-2 discontinued">Modify order</button>
            <span className="additional-settings-wrapper">
              <button className="additional-settings button-type-2 discontinued"></button>
            </span>
            <button className="primary-button button-type-2 discontinued">+ MyRemedy Weekly 8mg</button>
          </div>
        </div>
        <img className="spinner" src={require('../../../../img/components/Spinner-0.9s-200px.svg')} alt="spinner" />
      </div>
    )
  }
}


export default PatientBoxLoading;

