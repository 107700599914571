import { app, usersService, verificationService,} from '../index';

export async function signup(payload) {
  try {
    let auth = await usersService.create(payload);
    app.logout();
    let result = await app.authenticate({
      strategy: 'jwt',
      accessToken: auth.accessToken,
    });
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function login(payload) {
  try {
    app.logout();
    return await app.authenticate({
      strategy: 'local',
      ...payload,
    });
  } catch (err) {
    console.error("Authentication error: " + err);
    return {};
  }
}

export function logout() {
  return app.logout();
}

export async function auth() {
  try {
    const token = await app.passport.getJWT();
    if (!token) {
      return {error: "No token"};
    }
    await app.passport.verifyJWT(token);
    const user = await usersService.get(1, {query:{login:true}});
    return user;
  } catch (err) {
    console.log(err);
    app.logout();
    return {error: err};
  }
}

export async function userPatch(payload) {
  try {
    console.log(JSON.stringify(payload));
    let { data } = payload;
    
    return await usersService.patch(1, data);
  } catch (err) {
    console.log(err);
    return {
      error: true,
      message: err.message,
      err,
      validation: err.data?err.data.validation:{}
    };
  }
}



export async function forgotPassword(payload) {
  try {
    return await verificationService.create({
      ...payload,
      type: "forgot"
    });
  } catch (err) {
    console.log(err);
    return {err};
  }
}

export async function resetPassword(payload) {
  try {
    return await verificationService.create({
      type: "password-reset",
      ...payload
    });
  } catch (err) {
    console.log(err);
    return {err};
  }
}

export async function tokenLogin(payload) {
  try {
    let {accessToken} = await verificationService.create({
      type: payload.type || "login",
      email: payload.email,
      userId: payload.userId,
      token: payload.token
    });
    app.logout();
    return await app.authenticate({
      strategy: 'jwt',
      accessToken: accessToken,
    });
  } catch (err) {
    console.error("Authentication error: " + err);
    return {error: err};
  }
}

export async function secureLink(payload) {
  try {
    return await verificationService.create({
      type: "create-login-link",
      email: payload.email,
    });
  } catch (err) {
    console.log(err);
    return {err};
  }
}

export async function verification(payload) {
  try {
    return await verificationService.create({
      type: `validate-${payload.type}`,
      email: payload.email,
      token: payload.token
    });
  } catch (err) {
    console.log(err);
    return {err};
  }
}

export async function sendValidationMessage(payload) {
  let type = payload.mobileNumber?'mobile':'email';
  let data = {
    email: payload.email,
    type: `send-${type}-verification`,
    continue: payload.continue
  }
  if (payload.mobileNumber) {
    data.mobileNumber = payload.mobileNumber;
  }
  try {
    return await verificationService.create(data);
  } catch (err) {
    console.log(err);
    return {err};
  }
}