import React from "react";
import { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { closeModal } from 'modules/modals/actions';
import Navigation from "./components/Navigation";

function mapStateToProps(state) {
  return {
    currentModal: (state.modals) ? state.modals.currentModal : "",
    externalUrl: (state.modals) ? state.modals.data : "",
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal,
  }, dispatch);
}

class LeaveSite extends Component {
  render() {
    console.log(this.props.currentModal);
    if (this.props.currentModal === "LeaveSite") {
      return (
        <div className="modal-content">
          <h1>Leaving MedAX</h1>
          <p>You are leaving MedAX. MedAX is not responsible for the content or availability of the linked sites.</p>
          <Navigation secondaryText="Back to MedAX" secondaryLink={() => this.props.closeModal()} primaryText="Continue" primaryLink={this.props.to} external target={this.props.externalUrl} />
        </div>
      )
    } else {
      return "";
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaveSite);