import { takeEvery, call, put } from 'redux-saga/effects';

import {
  logout,
  signup,
  login,
  auth,
  userPatch,

  forgotPassword,
  resetPassword,
  tokenLogin,
  secureLink,
  verification,
  sendValidationMessage
 } from './api';

import {
  receiveAuth,
  requestAuth,
  receivePatchUser,
  REQUEST_LOGIN,
  REQUEST_LOGOUT,
  REQUEST_SIGNUP,
  REQUEST_AUTH,
  REQUEST_PATCH_USER,

  REQUEST_FORGOT_PASSWORD,
  REQUEST_PASSWORD_RESET,
  REQUEST_TOKEN_LOGIN,
  REQUEST_VERIFICATION,
  REQUEST_VALIDATION_MESSAGE,
  REQUEST_SECURE_LINK
} from './actions';


function* callLogin({ payload: { data, callback } }) {
  let result = yield call(login, data);
  if (!result.accessToken) {
    callback ({
      success: false,
      message: "Invalid username or password"
    });
    return;
  }
  yield put(requestAuth((user)=>{
    callback ({
      success: !user.error,
      errorMessage: user.error && user.error.message
    });
  }));
}

export function* loginSaga() {
  yield takeEvery(REQUEST_LOGIN, callLogin);
}

function* callSignup({ payload: { callback, data } }) {
  let result = yield call(signup, data);
  console.log(result);
  if (result.errors) {
    callback({success: false});
  } else {
    yield put(requestAuth(()=>callback({success: true})));
  }
}

export function* signupSaga() {
  yield takeEvery(REQUEST_SIGNUP, callSignup);
}

function* callLogout({ payload: { path } }) {
  yield call(logout);
  //yield put(receiveLogout({}));
  console.log("PATH: " + path);
  let query = '';
  if (path) {
    query = `?next=${path}`;
  }
  window.location = window.location.origin + "/login" + query;
}

export function* logoutSaga() {
  yield takeEvery(REQUEST_LOGOUT, callLogout);
}

function* callAuth({ payload }) {
  const user = yield call(auth);
  if (!user.error) {
    yield put(receiveAuth(user));
  }
  if (typeof(payload) === 'object') {
    if (user.error) {
      payload.onFail && payload.onFail(user.error);
    } else {
      payload.onSuccess && payload.onSuccess(user);
    }
  } else {
    payload(user);
  }
}

export function* authSaga() {
  yield takeEvery(REQUEST_AUTH, callAuth);
}

function* callPatchUser({ payload }) {
  console.log(JSON.stringify(payload.data, null, 2));
  let latePatchData = {};
  if (payload.data.email || payload.data.password || payload.data.patient || payload.waitForResult) {
    latePatchData = {...payload.data};
    if (payload.data.email) {
      latePatchData.newEmail = payload.data.email;
    }
    if (payload.data.mobileNumber) {
      latePatchData.newMobileNumber = payload.data.mobileNumber;
    }
    delete latePatchData.patient;
  } else {
    yield put(receivePatchUser({...payload.data}));
  }

  payload.callbackEarly && payload.callbackEarly();

  const value = yield call(userPatch, payload);
  if (value.error) {
    payload.callback && payload.callback(value);
    return;
  }

  yield put(receivePatchUser({...latePatchData, 
    email: value.email,
    mobileNumber: value.mobileNumber,
    signupStage: value.signupStage,
    patients: value.patients
  }));

  payload.callback && payload.callback(value);
}

export function* userPatchSaga() {
  yield takeEvery(REQUEST_PATCH_USER, callPatchUser);
}



function* callForgotPassword({ payload }) {
  console.log("callForgotPassword");
  let message = yield call(forgotPassword, payload.data);
  console.log(message);
  payload.callback(message);
}

export function* forgotPasswordSaga() {
  yield takeEvery(REQUEST_FORGOT_PASSWORD, callForgotPassword);
}

function* callResetPassword({ payload }) {
  let result = yield call(resetPassword, payload.data);
  payload.callback(result);
}

export function* passwordResetSaga() {
  yield takeEvery(REQUEST_PASSWORD_RESET, callResetPassword);
}

function* callTokenLogin({ payload }) {
  let result = yield call(tokenLogin, payload);
  if (!result.err) {    
    yield put(requestAuth(()=>{}));
    yield put(receivePatchUser({...result, isVerified: 1, verifyFinished: true}));
  } else {
    yield put(receivePatchUser({verifyFinished: true}));
  }
  yield put(receivePatchUser({verifyFinished: true}));
  payload.callback(result);
}

export function* tokenLoginSaga() {
  yield takeEvery(REQUEST_TOKEN_LOGIN, callTokenLogin);
}

function* callSecureLink({ payload }) {
  const result = yield call(secureLink, payload);
  payload.callback(result);
}

export function* secureLinkSaga() {
  yield takeEvery(REQUEST_SECURE_LINK, callSecureLink);
}

function* callVerification({ payload }) {
  const result = yield call(verification, payload);
  if (!result.err) {    
    yield put(receivePatchUser({...result, isVerified: 1, verifyFinished: true}));
  } else {
    yield put(receivePatchUser({verifyFinished: true}));
  }
  payload.callback(result);
}

export function* VerificationSaga() {
  yield takeEvery(REQUEST_VERIFICATION, callVerification);
}

function* callSendValidationMessage({ payload }) {
  const result = yield call(sendValidationMessage, payload);
  if (result.err && result.err.message === 'SMS Limit Exceeded') {
    yield put(receivePatchUser(result.err.data));
  }
  payload.callback(result);
}

export function* validationEmailSaga() {
  yield takeEvery(REQUEST_VALIDATION_MESSAGE, callSendValidationMessage);
}
