import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import { Field, Select, Checkbox, UnsavedChangesPrompt } from "components/Forms"
import { boolean, gender, treatmentStablised, drugAddiction, durationOfInitialTreatment } from "utils/selectValues";
import moment from 'moment';
import Warning from "../components/Warning";

import Sidebar from "../components/Sidebar"
import Navigation from "../components/Navigation";
import PatientProgressBar from "../components/PatientProgressBar";
import { requestPatchUser } from 'modules/user/actions'

import PdfDocument from "../components/PdfDocument"
import PatientConsent from "pdf/4_pfp-patient-consent-form.v02.pdf";
import filterInitialValues from 'utils/filterInitialValues'
import getQueryParams from "utils/getQueryParams";

import {_required, _email, _mobile} from "utils/validation"
let required = _required();
let email = _email();
let mobile = _mobile();
let requiredDate = _required("Please enter a valid date in the format: DD/MM/YY.");
let dobValidation = value => {
  let diff = moment().diff(moment(value), 'years');
  if (diff < 16) {
    return "Patient must be 16 years of age or older."
  }
}
const requireYes = value => value !== 'false' ? undefined : 'Patient cannot be enrolled if requirement not met.';
let durationOfInitialTreatmentValidation = value => value !== "Days: Less than 7" ? undefined : 'Initial treatment duration must be at least 7 days.';

let requiredGroup = (value, allValues) => {
  if (!allValues.user) return undefined;

  var emailExists = (allValues.user.patient.email !== undefined && allValues.user.patient.email !== '');
  var mobileNumberExists = (allValues.user.patient.mobileNumber !== undefined && allValues.user.patient.mobileNumber !== '');
  var resourcesCheckedExists = !(!allValues.user.patient.resourcesCheck || allValues.user.patient.resourcesCheck.length === 0);

  if (emailExists || mobileNumberExists || resourcesCheckedExists) {
    return undefined;
  }

  return 'This field is required.';
}


function mapStateToProps(state, ownProps) {

  let queryParams = getQueryParams();
  let id = queryParams.patientId || ownProps.location.data && ownProps.location.data.patientId;

  let patient;
  if (id) {
    patient = state.user.patients && state.user.patients.filter(patient=>patient.id == id)[0];
  } else {
    patient = null;
  }

  let otherInitialValues = {};
  if (patient) {
    otherInitialValues = {
      olderThan16: 'true',
      opiodDependence: 'true',
      stablised: 'true', 
      contraindications: 'true',
      patientReviewed: 'true',
    }
  }

  return {
    patient,

    initialValues: {
      user: {
        patient: {
          ...patient,
          treatmentSiteId: (patient && patient.treatmentSiteId) || state.user.myremedy.treatmentSite
        },
      },
      ...otherInitialValues
    },
    fields: (state.form.PatientEnrol && state.form.PatientEnrol.registeredFields) || {},
    treatmentSites: state.user.treatmentSites ? state.user.treatmentSites.map(site=>({value:site.id, label:site.name})) : []
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser
  }, dispatch);
}

class PatientEnrol extends Component {

  scrollToError() {
    setTimeout(() => {
      if (window.$('.error').length) {
        window.$('html, body').animate({
            scrollTop: window.$('.error').offset().top - 150
        }, 200);
      }
    }, 10);
  }


  submit(_results) {
    return new Promise((resolve, reject) => {
      _results.user.patient.initials = _results.user.patient.initials && _results.user.patient.initials.toUpperCase();
      _results.user.patient.dob = moment(_results.user.patient.dob).format('YYYY-MM-DD');

      let results = filterInitialValues(_results, this.props.fields, this.props.initialValues);

      if (!results.user.patient || results.user.patient.length === 0) {
        this.props.history.push({
          pathname: "/myremedy/patients/onboarding-drug-order/?patientId=" + this.props.patient.id,
          data: this.props.patient.id
        });
      }

      let materialProvidedBy = [];
      if (_results.user.patient.email) materialProvidedBy.push('email');
      if (_results.user.patient.mobileNumber) materialProvidedBy.push('sms');
      if (_results.user.patient.resourcesCheck) materialProvidedBy.push('hardcopies');

      results.user.patient.materialProvidedBy = materialProvidedBy.join(', ');

      results.user.patient.id = (this.props.patient)?this.props.patient.id:"create";
      results.user.patient.state = "incomplete";
      results.user.patient.treatmentSiteId = _results.user.patient.treatmentSiteId;

      this.props.requestPatchUser({
        data: results.user,
        callback: (result) => {
          if (!result.error) {
            let patientId = (this.props.patient)?this.props.patient.id : result.patients[result.patients.length - 1].id;
            this.props.history.push({
              pathname: "/myremedy/patients/onboarding-drug-order/?patientId=" + patientId,
              data: {
                patientId: (this.props.patient)?this.props.patient.id : result.patients[result.patients.length - 1].id
              }
            });
            return resolve();
          } else {
            this.scrollToError();
            return reject(new SubmissionError(result.err.data.validation));
          }
        }
      });
    });
  }

  submitWrapper(e) {
    let errors = this.props.handleSubmit(results => this.submit(results))(e);

    if (errors) {
      this.scrollToError();
    }
    return false;
  }

  render() {
    const authorisationHardCopies = [
      { value: 'true', label: 'I have provided the patient with hardcopies of the education resources.' },
    ];

    const authorisationReview = [
      { value: 'true', label: 'I confirm that I have reviewed the Patient Education Material with the patient, and that the patient has provided informed consent.' },
    ];

    if (this.props.patient && this.props.patient.shipmentDate && this.props.patient.orderConfirmedDate === this.props.patient.shipmentDate) {
      return (
        <main className={"dashboard content-page " + this.props.type}>
          <Sidebar />
          <div className="content">
            <h2>Your order has been submitted</h2>
          </div>
        </main>
      );
    }


    return (
      <main className={"dashboard content-page " + this.props.type}>
        <Sidebar />
        <PatientProgressBar step="1" />

        <div className="content">
          <form onSubmit={(e)=>this.submitWrapper(e)} autoComplete="off">
            <input hidden autoComplete="off" />
            <h1>Enrol new patient</h1>
            <p>Please review the full Product Information (PI) for MyRemedy before prescribing. Full PIs can be accessed <NavLink onClick={()=>{alert("Link disabled for demo."); return false}}>here</NavLink>.</p>

            <h2>Patient eligibility critera</h2>
            <p>Please confirm that this patient meets the following criteria:</p>
            <Checkbox radio validate={[required, requireYes]} title="Criteria to be added" styling="float" name="olderThan16" options={boolean} />

            <h2>Patient details</h2>
            <Select validate={[required]} disabled styling="float l" name="user.patient.treatmentSiteId" type="text" title="Treatment site" options={this.props.treatmentSites} />
            <Field validate={[required]} styling="float l" name="user.patient.initials" type="text" title="Initials" maxlength={4} />
            <Select validate={[requiredDate, dobValidation]} date disableCalendar isClearable styling="float r" name="user.patient.dob" type="text" title="Date of birth (DD/MM/YY)" noOptionsMessage={() => null} />
            <Select validate={[required]} styling="float l" name="user.patient.sex" type="text" title="Sex" options={gender} />

            <h2>Patient resources</h2>
            <p>If you have not already done so, please provide your patient with access to:</p>
            <ul>
              <li>MyRemedy Consumer Medicines Information (CMI)</li>
              <li>MyRemedy Patient Guide</li>
            </ul>
            <p>You may enter the patient’s details below to send a link to the resources via email or SMS.</p>
            <br />
            <Warning>
              <p>Your patients details will not be stored or collected by the PFP administrator via the website or any other device.</p>
            </Warning>
            <br />

            <Field validate={[email]} styling="float l" name="user.patient.email" type="email" title="Patient email address" />
            <Field validate={[mobile]} styling="float r" name="user.patient.mobileNumber" type="tel" title="Patient mobile number" />
            <p>The patient resources link will send upon confirmation of patient enrolment.</p>
            <p>Alternatively you may <NavLink onClick={()=>{alert("Link disabled for demo."); return false}}>print the resources</NavLink> for the patient.</p>
            <Checkbox styling="float single" name="user.patient.resourcesCheck" options={authorisationHardCopies} validate={[requiredGroup]} />

            <h2>Patient consent</h2>
            <PdfDocument last documentName="Patient consent form" link={''} />
            <ol>
              <li><a rel="noopener noreferrer" onClick={()=>{alert("Link disabled for demo."); return false}}>Print</a> the Patient Consent form.</li>
              <li>Ask your patient to sign</li>
              <li>Provide your patient them with a copy and retain the signed form in your patient’s records.</li>
            </ol>

            <Checkbox validate={[required]} styling="float single" name="patientReviewed" options={authorisationReview} />
            <Navigation secondaryText="Back" secondaryLink="/myremedy/patients" primaryText="Continue" primaryLink="/myremedy/patients/onboarding-drug-order" />
            <UnsavedChangesPrompt when={!(this.props.pristine || this.props.submitting)} />
          </form>
        </div>
      </main>
    )
  }
}

PatientEnrol = reduxForm({
  form: 'PatientEnrol' // a unique name for this form
})(PatientEnrol);

export default connect(mapStateToProps, mapDispatchToProps)(PatientEnrol);
