import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import moment from 'moment';
import Sidebar from "./components/Sidebar"
import PatientBox from "./components/PatientBox"
import PatientBoxLoading from "./components/PatientBoxLoading"

import NavButton from "components/NavButton";

import { Select } from "components/Forms"

import getCurrentDate from "utils/getCurrentDate"

function mapStateToProps(state) {
  return {
    isValidated: state.user.myremedy.isValidated,
    patients: state.user.patients
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

const filter = [
  { value: {sortItem: 'initials', sortOrder: 1}, label: 'Initials (A - Z)' },
  { value: {sortItem: 'initials', sortOrder: -1}, label: 'Initials (Z - A)' },
  { value: {sortItem: 'dob', sortOrder: 1}, label: 'DOB (Ascending)' },
  { value: {sortItem: 'dob', sortOrder: -1}, label: 'DOB (Descending)' },
];

class Patients extends Component {

  constructor() {
    super();
    this.state = {
      sortItem: 'initials', 
      sortOrder: 1
    }
  }

  changeSortOrder(value) {
    this.setState(state=>({...state, ...value}));
  }

  render() {
    
    let patients = this.props.patients.map(patient=>{
      if (patient.state === 'active' && patient.shipmentDate) {
        let shipmentDate = moment.utc(patient.shipmentDate);
        let currentDate = getCurrentDate();
        currentDate.add(7, 'days');
        let state = patient.state;
        let isConfirmed = false;
        if (patient.orderConfirmedDate && moment.utc(patient.orderConfirmedDate).isSame(shipmentDate, 'day')) {
          isConfirmed = true;
        }
        if (currentDate.isAfter(shipmentDate) && !isConfirmed) {
          state = 'confirm';
        }
        let is8mgConfirmed = false;
        if (patient.additional8mgOrderConfirmedDate && moment.utc(patient.additional8mgOrderConfirmedDate).isSame(moment.utc(patient.additional8mgShipmentDate), 'day')) {
          is8mgConfirmed = true;
        }
        return {...patient, state, isConfirmed, is8mgConfirmed};
      } else {
        return {...patient};
      }
    });

    if (this.state.sortItem === 'initials') {
      patients.sort((a,b)=>{
        if (a.initials > b.initials) {
          return 1 * this.state.sortOrder;
        } else if (a.initials < b.initials) {
          return -1 * this.state.sortOrder;
        } else {
          return 0;
        }
      });
    } else if (this.state.sortItem === 'dob') {
      patients.sort((_a,_b)=>{
        let a = moment(_a.dob);
        let b = moment(_b.dob);
        if (a.isAfter(b)) {
          return 1 * this.state.sortOrder;
        } else if (a.isBefore(b)) {
          return -1 * this.state.sortOrder;
        } else {
          return 0;
        }
      });
    }

    let unconfirmedPatients = this.props.patients.filter(patient=>patient.state === 'confirm');

    if (!this.props.isValidated) {
      return (
        <main className={"dashboard content-page " + this.props.type}>
          <Sidebar />
          <div className="content">
            <h1>Patient Dashboard</h1>
            <h2>Access pending validation of your registration.</h2>
            <p>Your registration has been submitted for validation.</p>
            <p><strong>Please note:</strong> If an account for your site has not been
            confirmed, access to the dashboard may take longer than
            24 hours. If you have questions, please contact the PFP
            administrator.</p>
            <p>You will receive an email notification from the PFP
            administrator to confirm approval of your registration and
            that access to the Patient Dashboard has been activated.</p>
          </div>
        </main>
      )
    }

    switch ("2") {
      case "1":
        return (
          <main className={"dashboard content-page " + this.props.type}>
            <Sidebar />
            <div className="content">
              <h1>Patient Dashboard</h1>
              <h2>Patient enrolment has not opened for this program</h2>
              <p>You are fully registered to participate in the MyRemedy Weekly/Monthly PFP. However, Patient Enrolment has not yet opened.</p>
              <p>You will receive a notification from the PFP Administrator once patient enrolment is open, with a link to return to the site to start the patient enrolment process.</p>
            </div>
          </main>
        )
      case "2":
        return (
          <main className={"dashboard content-page " + this.props.type}>
            <Sidebar />
              <div className="content">
                <h1>Patient Dashboard</h1>
                {this.props.patients.length < 10 &&
                  <NavButton to="/myremedy/patients/onboarding-enrol-patient/?patientId=null" className="add-new-patient button-type-1">Enrol new patient</NavButton>
                }
                {this.props.patients.length > 0 ?
                  <div>
                    <div className="search-details">
                      <p>You have enrolled <strong className="blue">{this.props.patients.length} patients</strong> with <strong>{10 -this.props.patients.length} patients</strong> remaining. {unconfirmedPatients.length ? <span>Please confirm the orders for <strong>{unconfirmedPatients.length} patients.</strong></span>:""}</p>
                      <form>
                        <Select defaultValue={filter[0]} onChange={value=>this.changeSortOrder(value)} styling="float filter l" name="user.patient.filter" options={filter} isSearchable={false} />
                      </form>
                    </div>
                    <hr />

                    {/* Patient Box */}
                    {patients.map(patient=><PatientBox patient={patient} key={patient.id} />)}
                  </div>
                :
                  <div className="no-patient-enrolled">
                    <h2>You have no patients enrolled.</h2>
                    <p>Click on <strong>Enrol new patient</strong> to enrol a patient in the MyRemedy Weekly/Monthly Product Familiarisation Program (PFP).</p>
                  </div>
                }
            </div>
          </main>
        )
    }
  }
}

Patients = reduxForm({
  form: 'Patients' // a unique name for this form
})(Patients);

export default connect(mapStateToProps, mapDispatchToProps)(Patients);

