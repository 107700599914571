import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import moment from 'moment'

import Sidebar from "../components/Sidebar"
import { ActivePatientDetailBox, OrderExtraDetailBox } from "../components/DetailBox"
import getQueryParams from "utils/getQueryParams";
import Navigation from "../components/Navigation";
import Warning from "../components/Warning";
import { requestPatchUser } from 'modules/user/actions';

import getCurrentDate from "utils/getCurrentDate"

let formatDate = date => moment.utc(date).format('DD/MM/YYYY')

function mapStateToProps(state, ownProps) {

  let queryParams = getQueryParams();
  let id = queryParams.patientId || ownProps.location.data && ownProps.location.data.patientId;

  let patient;
  if (id) {
    patient = state.user.patients && state.user.patients.filter(patient=>patient.id == id)[0];
  } else {
    patient = null;
  }

  return {
    patient
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestPatchUser
  }, dispatch);
}

class OrderExtraConfirm extends Component {

  constructor() {
    super();
    this.state = {}
  }

  submit(results) {
    return new Promise((resolve, reject) => {

      let shipmentDate = moment.utc(this.props.patient.additional8mgShipmentDate);
      let currentDate = getCurrentDate();
      if (currentDate.hour() >= 12) {
        currentDate.add(1, 'd');
      }
      if (shipmentDate.isBefore(currentDate, 'day')) {
        this.setState(state=>({...state, oldShipmentDate: formatDate(this.props.patient.additional8mgShipmentDate)}));
        shipmentDate.add(1, 'd');
        while (shipmentDate.day() >= 5 || shipmentDate.day() === 0) {
          shipmentDate.add(1, 'd');
        }

        this.props.requestPatchUser({
          data: {
            patient: {
              id: this.props.patient.id,
              additional8mgShipmentDate: shipmentDate.format('YYYY-MM-DD')
            }
          },
          callback: (result) => {
            if (!result.error) {
              return reject(new SubmissionError({
                _error: "Invalid shipment date"
              }));
            } else {
              return reject(new SubmissionError({
                _error: result.error.message
              }));
            }
          }
        });
        return;
      }

      let totalAdditional8mgUnits = parseInt('' + this.props.patient.totalAdditional8mgUnits) || 0;

      totalAdditional8mgUnits += parseInt(this.props.patient.additional8mgUnitsOrdered) || 0;

      this.props.requestPatchUser({
        data: {
          patient: {
            id: this.props.patient.id,
            additional8mgOrderConfirmedDate: shipmentDate.format('YYYY-MM-DD'),
            additional8mgOrderConfirmationTime: moment().format('DDMMYY HH:mm'),
            totalAdditional8mgUnits
          }
        },
        callback: (result) => {
          if (!result.error) {
            this.props.history.push('/myremedy/patients/');
            return resolve();
          } else {
            return reject(new SubmissionError({
              _error: result.error.message
            }));
          }
        }
      });
    });
  }

  render() {

    if (!this.props.patient) {
      return "";
    }

    if (this.props.patient.additional8mgShipmentDate && this.props.patient.additional8mgOrderConfirmedDate === this.props.patient.additional8mgShipmentDate) {
      return (
        <main className={"dashboard content-page " + this.props.type}>
          <Sidebar />
          <div className="content">
            <h2>Your order has been submitted</h2>
          </div>
        </main>
      );
    }

    return (
      <main className={"dashboard content-page " + this.props.type}>
        <Sidebar />
        <div className="content">
            <form onSubmit={this.props.handleSubmit(results => this.submit(results))} autoComplete="off">
              <h1>Confirm order</h1>
              <p>Please review the details below before confirming the order.</p>
              <h2>Patient</h2>
              <ActivePatientDetailBox patient={this.props.patient} />

              <h2>Order</h2>
              <OrderExtraDetailBox patient={this.props.patient} location={"/myremedy/patients/order-extra/?patientId=" + this.props.patient.id} />
              {this.props.error && <Warning>
                <p>As it is now after 12pm Mon-Wed (AEST) date of shipment has been changed from <strong>{this.state.oldShipmentDate}</strong> to <strong>{formatDate(this.props.patient.additional8mgShipmentDate)}</strong>. You may modify the administration date or click continue to accept the change. </p>
              </Warning>
              }
              <Navigation secondaryText="Back" secondaryLink={"/myremedy/patients/order-extra/?patientId=" + this.props.patient.id} primaryText="Confirm" primaryLink="" />

          </form>          
        </div>
      </main>
    )
  }
}

OrderExtraConfirm = reduxForm({
  form: 'Step4' // a unique name for this form
})(OrderExtraConfirm);

export default connect(mapStateToProps, mapDispatchToProps)(OrderExtraConfirm);