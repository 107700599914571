import React, { Component } from "react";

import WeeklyCMI from "pdf/Weekly_CMI.v1.0 -2018-11-28.pdf";
import MonthlyCMI from "pdf/Monthly_CMIv1.0 - 2018-11-28.pdf";
import PdfDocument from "./components/PdfDocument"

class Patients extends Component {
  render() {
    return (
      <main className={"dashboard content-page " + this.props.type}>
        <div className="content">
          <h1>Patient resources</h1>
          <PdfDocument documentName="MyRemedy Weekly Consumer Medicines Information" link={''} />
          <PdfDocument documentName="MyRemedy Monthly Consumer Medicines Information" link={''} />
          <PdfDocument documentName="Patient Alert Card" link={''} />
          <PdfDocument documentName="Patient Education Module" link={''} /> 
        </div>
      </main>
    )
  }
}

export default Patients;