import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';

import {requestSignup} from '../../modules/user/actions'

import ContainerBox from "./components/ContainerBox";
import Navigation from "./components/Navigation";
import { Field } from "components/Forms"
import {_required, _email, _password} from "utils/validation"

let required = _required();
let email = _email();
let password = _password();

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    requestSignup,
  }, dispatch);
}


class SignUp extends Component {

  submit(results) {
    console.log(JSON.stringify(results, null, 2));
    return new Promise((resolve, reject) => {
      if (results.user.password) {
        if (results.user.password !== results['password_confirm']) {
          return reject(new SubmissionError({
            'password_confirm': 'Passwords do not match'
          }));
        }
      }

      this.props.requestSignup({
        data: results.user,
        callback: (result)=>{
          if (result.success) {
            this.props.history.push('/signup-confirm-email');
            return resolve();
          } else {
            return reject(new SubmissionError({
              user: {
                email: 'Email address already signed up.'
              } 
            }));
          }
        }
      });
    });
  }

  render() {
    return (
      <ContainerBox type="signup" title="Register account" isLoading={this.props.submitting}>
        <form onSubmit={this.props.handleSubmit(results => this.submit(results))} autoComplete="off">
          <input hidden autoComplete="off" />
          <Field name="user.email" type="email" title="Enter your email address" validate={[required, email]} autoCapitalize="none" autoCorrect="none" />
          <Field name="user.password" type="password" title="Enter your password" validate={[required, password]} autoCapitalize="none" autoCorrect="none" />
          <Field name="password_confirm" type="password" title="Confirm your password" validate={[required]} autoCapitalize="none" autoCorrect="none" />
          <Navigation secondaryText="Back to Log In" secondaryLink="/login" primaryText="Sign up" primaryLink="/signup-confirm-email" />
        </form>
      </ContainerBox>
    )
  }
}

SignUp = reduxForm({
  form: 'SignUp' // a unique name for this form
})(SignUp);

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);