import React, { Component } from "react";
import ExternalLink from "components/ExternalLink"

function linkDisabled() {
  alert("Link disabled for demo.");
  return false;
}

class PdfDocument extends Component {
  render() {
    return (
      <div className={"pdf-link" + (this.props.last?" last-of-element":"")}>
        {this.props.external ?
          <ExternalLink className="pdf-link-wrapper" rel="noopener noreferrer" onClick={linkDisabled} >
            <div className="left">{this.props.documentName}</div>
            <div className="right"></div>
          </ExternalLink>
          :
          <a className="pdf-link-wrapper" rel="noopener noreferrer" target="_blank" onClick={linkDisabled} >
            <div className="left">{this.props.documentName}</div>
            <div className="right"></div>
          </a>
        }
      </div>
    )
  }
}

export default PdfDocument;