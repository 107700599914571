import React, { Component } from "react";
import Sidebar from "./components/Sidebar"

class PatientsPendingValidation extends Component {
  render() {

    return (
      <main className={"dashboard content-page " + this.props.type}>
        <Sidebar />
        <div className="content">
          <h1>Patient Dashboard</h1>
          <h2>Patient enrolment has not opened for this program</h2>
          <p>You are fully registered to participate in the MyRemedy PFP. However, Patient Enrolment has not yet opened.</p>
          <p>You will receive a notification from the PFP Administrator once patient enrolment is open, with a link to return to the site to start the patient enrolment process.</p>
        </div>
      </main>
    )
  }
}


export default PatientsPendingValidation;