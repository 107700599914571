import React, { Component } from "react";

import Navigation from "./components/Navigation";
import OnboardingContainer from "./components/OnboardingContainer";

class Step1 extends Component {
  render() {
    return (
      <OnboardingContainer step="1">

        <h1>Welcome to the MyRemedy Product Familiarisation Program (PFP)</h1>
        <p>Introduction content will appear here.</p>
        <h3>Registration steps</h3>
        <ol>
          <li>Read the Privacy Statement, Terms of Use and create your profile (approx. 5 minutes)</li>
          <li>Complete the education module (approx. 10 minutes)</li>
        </ol>
        <Navigation step={1} primaryText="Next" primaryLink="/myremedy/setup/step-2" />

      </OnboardingContainer>
    )
  }
}

export default Step1;