export default function hasmyremedyAccess(user) {
  if (!(user.meta.specialisation 
    && user.meta.specialisation.length 
    && user.meta.specialisation.find(a => a.indexOf("Addiction") !== -1))) {
    return false;
  }

  if (user.profession === "General Practitioner" || user.profession === "Advanced Trainee" || user.profession === "Specialist") {
    return true;
  }

  return false;
}