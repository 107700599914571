import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import Sidebar from "../components/Sidebar"
import { Field, Select } from "components/Forms"
import { gender, treatmentStablised, drugAddiction, durationOfInitialTreatment } from "utils/selectValues";
import getQueryParams from "utils/getQueryParams";
import Navigation from "../components/Navigation"

function mapStateToProps(state, ownProps) {

  let queryParams = getQueryParams();
  let id = queryParams.patientId || ownProps.location.data && ownProps.location.data.patientId;

  let patient;
  if (id) {
    patient = state.user.patients && state.user.patients.filter(patient => patient.id == id)[0];
  } else {
    patient = null;
  }

  return {
    patient,
    initialValues: {
      user: {
        patient
      }
    },
    treatmentSites: state.user.treatmentSites ? state.user.treatmentSites.map(site => ({ value: site.id, label: site.name })) : []
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

class PatientDetails extends Component {
  render() {

    return (
      <main className={"dashboard content-page " + this.props.type}>
        <Sidebar />
        <div className="content">
          <h1>Patient details</h1>
          <form autoComplete="off">
            <input hidden autoComplete="off" />
            <Select disabled styling="float l" name="user.patient.treatmentSiteId" type="text" title="Treatment site" options={this.props.treatmentSites} />
            <Field disabled styling="float l" name="user.patient.initials" type="text" title="Initials" />
            <Select disabled date disableCalendar styling="float r" name="user.patient.dob" type="text" title="Date of birth" noOptionsMessage={() => null} />
            <Select disabled styling="float l" name="user.patient.sex" type="text" title="Sex" options={gender} />
            <Navigation secondaryText="Back" secondaryLink={"/myremedy/patients"} primaryText="" primaryLink="" />
          </form>
        </div>
      </main>
    )
  }
}

PatientDetails = reduxForm({
  form: 'PatientDetails' // a unique name for this form
})(PatientDetails);

export default connect(mapStateToProps, mapDispatchToProps)(PatientDetails);