import React, { Component } from "react";
import Sidebar from "./components/Sidebar"

class ProductPrivacyStatement extends Component {
    render() {
        return (
            <main className={"dashboard content-page " + this.props.type}>
                <Sidebar />
                <div className="content">
                <h1>Privacy Statement</h1>
                    <p>Company Privacy Statement will appear here.</p>
                </div>
            </main>
        )
    }
}

export default ProductPrivacyStatement;