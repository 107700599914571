import React, { Component } from "react";
import SelectField from 'react-select'
import { Field as ReduxField } from 'redux-form';
import DatePicker from './DatePicker';
import moment from 'moment'
import { Prompt } from "react-router-dom";

// NOTE: If you change one field, update the rest.
// Current fields: Field, Select

export class FieldComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      hidden: true,
    };

    this.toggleShow = this.toggleShow.bind(this);
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  render() {
    return (
      <span className={"field" + ((this.props.id) ? (" " + this.props.id) : "")
        + ((this.props.type === "password") ? (" password") : "")
        + ((this.props.styling) ? (" " + this.props.styling) : "")
        + ((this.props.autoFocus) ? " auto-focus" : "")
        + ((this.props.disabled) ? " disabled" : "")
        + ((this.props.input.value) ? " not-empty" : "")
        + ((this.props.meta.active) ? " focus" : "")
        + ((this.props.meta.active) ? " focus" : "")
        + ((this.props.meta.touched && this.props.meta.error) ? " error" : "")} >

        <label>
          <input
            {...this.props}
            {...this.props.input}
            autoComplete="off"
            type={(this.props.type === "password" && this.props.input.value) ? (this.state.hidden ? "password" : "text") : this.props.type}
            id={this.props.id}
            name={this.props.name}
            maxLength={this.props.maxLength}
            inputMode={this.props.inputMode}
            pattern={this.props.pattern} />

          {(this.props.type === "password" && this.props.input.value) ? <div className={"show-hide-password " + (this.state.hidden ? "hide" : "show")} onClick={this.toggleShow}></div> : ""}

          <span className={"" + ((this.props.meta.visited) ? " animate" : "")}>{this.props.title}</span>
        </label>
        {this.props.meta.touched && this.props.meta.error &&
          <div className="error-text">{this.props.meta.error}</div>
        }
      </span>
    );
  }
}

export const Field = (props) => <ReduxField {...props} component={FieldComponent} />;

class SelectComponent extends Component {

  state = {
    valueExists: false,
    focus: false,
  };

  onChange = (_e) => {
    let e = _e;

    let valueExists = false;
    if (Array.isArray(e)) {
      valueExists = !!e.length;
      this.setState(state => ({ ...state, valueExists, value: [...e] }));
      e.value = e.map(x => x.value);
    } else {
      valueExists = !!(e && e.value);
      this.setState(state => ({ ...state, valueExists, value: { ...e } }));
    }

    if (e === null) {
      e = "";
    }

    this.props.input.onChange(e && e.value);
  }

  toggleMenuFocus = (e) => {
    if (e) {
      this.setState(state => ({ ...state, focus: true }));
    }
    this.props.input.onFocus();
  }

  toggleMenuBlur = (e) => {
    if (e) {
      this.setState(state => ({ ...state, focus: false }));
    }
    this.props.input.onBlur();
  }

  componentDidUpdate() {
    if (!this.state.value && this.props.input.value) {
      let val = this.props.input.value;

      let valueExists = true;
      if (Array.isArray(val)) {
        if (!val.length) {
          valueExists = false;
        }
        val = this.props.options.filter(x => val.indexOf(x.value) !== -1);
      } else {
        if (this.props.date) {
          val = new Date(val);
          let date = moment(val);
          val = {
            value: val,
            date,
            label: date.format('Do MMMM YYYY'),
            display: "calendar"
          }
          this.props.input.onChange(val.value);
        }
        if (this.props.options) {
          val = this.props.options.filter(x => x.value === val)[0];
          if (val === undefined) {
            return;
          }
        }
      }
      this.setState(state => ({ ...state, valueExists, value: val }));
    }
  }

  render() {
    return (
      <span className={"select"
        + ((this.props.date) ? " date" : "")
        + ((this.props.id) ? (" " + this.props.id) : "")
        + ((this.props.styling) ? (" " + this.props.styling) : "")
        + ((this.state.valueExists) ? "" : " empty")
        + ((this.state.focus) ? " focus" : "")
        + ((this.props.disabled) ? " disabled" : "")
        + ((this.props.meta.touched && this.props.meta.error) ? " error" : "")}>

        <label>
          <span className={"" + ((this.props.meta.visited) ? " animate" : "")}>{this.props.title}</span>
          {(this.props.date) ?
            <DatePicker {...this.props} value={this.state.value} classNamePrefix="select-field" onFocus={(e) => this.toggleMenuFocus(e)} onBlur={(e) => this.toggleMenuBlur(e)} onChange={(e) => this.onChange(e)} />
            :
            <SelectField {...this.props} value={this.state.value} classNamePrefix="select-field" options={this.props.options} onFocus={(e) => this.toggleMenuFocus(e)} onBlur={(e) => this.toggleMenuBlur(e)} onChange={(e) => this.onChange(e)} />
          }
        </label>
        {this.props.meta.touched && this.props.meta.error &&
          <div className="error-text">{this.props.meta.error}</div>
        }
      </span>
    );
  }
}

export const Select = (props) => <ReduxField {...props} component={SelectComponent} />

class CheckboxComponent extends Component {

  state = {
  };

  onChange = (_e) => {

    let checked = _e.target.checked;
    let val = _e.target.value;

    if (this.props.radio || this.props.single) {
      if (checked) {
        this.setState((_state) => {
          let state = { ..._state };
          state.value = val;
          return state;
        });
        
        this.props.input.onChange(val);
        return;
      } else {
        this.setState({ value: ''});
        this.props.input.onChange('');
        return;
      } 
    }

    let newValue = this.state.value ? [...this.state.value] : [];

    if (checked) {
      if (newValue.indexOf(val) === -1) {
        newValue.push(val);
      }
    } else {
      newValue = newValue.filter((v) => v !== val);
    }

    this.setState((_state) => {
      let state = { ..._state };
      state.value = newValue;
      return state;
    });

    this.props.input.onChange(newValue);
  }

  componentDidUpdate() {
    if (!this.state.value && this.props.input.value) {
      this.setState(state => ({ ...state, value: this.props.input.value }));
    }
  }

  checked = (val) => {
    if (this.props.input.value === undefined) {
      return false;
    }
    if (this.props.radio || this.props.single) {
      return this.props.input.value === val;
    }
    return this.props.input.value.indexOf(val) !== -1;
  }

  render() {
    return (
      <div className={"checkbox"
        + ((this.props.radio) ? " radio" : "")
        + ((this.props.id) ? (" " + this.props.id) : "")
        + ((this.props.styling) ? (" " + this.props.styling) : "")
        + ((this.props.autoFocus) ? " auto-focus" : "")
        + ((this.props.disabled) ? " disabled" : "")
        + ((this.props.meta.touched && this.props.meta.error) ? " error" : "")}>

        <span>{this.props.title}</span>
        {this.props.options.map((anObjectMapped, index) => {
          return <div key={index} className="option"><input checked={this.checked(anObjectMapped.value)} onChange={(e) => this.onChange(e)} type={(this.props.radio) ? "radio" : "checkbox"} name={this.props.uid} value={anObjectMapped.value} id={this.props.uid + "." + anObjectMapped.value} /><label htmlFor={this.props.uid + "." + anObjectMapped.value}>{anObjectMapped.label}</label></div>
        })}
        {this.props.meta.touched && this.props.meta.error &&
          <div className="error-text">{this.props.meta.error}</div>
        }
      </div>
    );
  }
}

export const Checkbox = (props) => <ReduxField {...props} uid={props.name} component={CheckboxComponent} />

export const UnsavedChangesPrompt = ({when}) => (
  <Prompt
    when={when}
    message='There are unsaved changes. Are you sure you want to leave this page?'
  />
)